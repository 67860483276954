// import {updateLoadingState} from "@Components/email-marketing-campaign-performance/email-marketing-campaign-performance-reducer";

import {PMW_COLORS_100, PMW_COLORS_PRIMARY} from '@Utils/color.util';

export const LOADING_STATES = Object.freeze({
  NOT_LOADED: 0,
  LOADING: 2,
  LOADED: 1,
});

export const ITEM_TYPES = Object.freeze({
  CAMPAIGNS: 'c',
  AUDIENCES: 'a',
  VIEWS: 'v',
});

export const METRIC_TYPES = Object.freeze({
  DELIVERY: 'delivery',
  CLICK: 'click',
  OPEN: 'open',
});

export const INPUT_HASH_INDEX = Object.freeze({
  ITEM_TYPE: 2,
  FIRST_SELECTED_ITEM_ID: 3,
  SECOND_SELECTED_ITEM_ID: 4,
});

export const DEFAULT_CAMPAIGN_METRIC_GRAPH_COLORS = Object.freeze({
  Primary: PMW_COLORS_100.PRIMARY,
  Secondary: PMW_COLORS_100.SECONDARY,
  Tertiary: PMW_COLORS_100.SUCCESS,
});

export const DEFAULT_CAMPAIGN_METRIC_PDF_GRAPH_COLORS = Object.freeze({
  Primary: PMW_COLORS_PRIMARY.PRIMARY_50,
  Secondary: PMW_COLORS_PRIMARY.PRIMARY_100,
  Tertiary: PMW_COLORS_PRIMARY.PRIMARY_200,
});

export const INPUT_SELECTED_ITEM_ID_INDEX = Object.freeze({
  FIRST_ID: 0,
  SECOND_ID: 1,
});

/**
 * Gets and returns the item contained in selectedItemIds[index], if found, and null otherwise.
 * @param {array} selectedItemIds
 * @param {number} index
 * @return {null|*}
 */
export const getSelectedItemIdAtIndex = (selectedItemIds, index) => {
  if (selectedItemIds.length > index) {
    return selectedItemIds[index];
  }
  return null;
};

/**
 * Returns the ID of the first selected item in the email campaigns performance view
 * @return {*|null}
 */
export const getFirstSelectedItemId = (selectedItemIds) => {
  return getSelectedItemIdAtIndex(selectedItemIds, INPUT_SELECTED_ITEM_ID_INDEX.FIRST_ID);
};

/**
 * Returns the ID of the second selected item in the email campaigns performance view
 * @return {*|null}
 */
export const getSecondSelectedItemId = (selectedItemIds) => {
  return getSelectedItemIdAtIndex(selectedItemIds, INPUT_SELECTED_ITEM_ID_INDEX.SECOND_ID);
};

// TODO: Add comments with these
export const getEmailMarketingCampaignsPerformanceStateFromStore = () => {
  return PMW.redux.store.getState().emailMarketingCampaignsPerformance;
};

export const getEmailMarketingCampaignSelectedItemTypeFromStore = () => {
  return getEmailMarketingCampaignsPerformanceStateFromStore().itemType;
};

export const getEmailMarketingCampaignsStateFromStore = () => {
  return getEmailMarketingCampaignsPerformanceStateFromStore().campaigns;
};

export const getMailingListsStateFromStore = () => {
  return getEmailMarketingCampaignsPerformanceStateFromStore().mailingLists;
};

export const getSelectedIdsStateFromStore = () => {
  return getEmailMarketingCampaignsPerformanceStateFromStore().selectedItemIds;
};

export const getFirstSelectedEmailMarketingCampaign = () => {
  const firstSelectedId = getFirstSelectedItemId(getSelectedIdsStateFromStore());
  const campaigns = getEmailMarketingCampaignsStateFromStore();

  return campaigns[firstSelectedId] ?? null;
};

export const getFirstSelectedMailingList = () => {
  const firstSelectedItem = getFirstSelectedItemId(getSelectedIdsStateFromStore());
  const mailingLists = getMailingListsStateFromStore();

  return mailingLists[firstSelectedItem] ?? null;
};

export const getFirstSelectedItem = () => {
  if (getEmailMarketingCampaignSelectedItemTypeFromStore() === ITEM_TYPES.CAMPAIGNS) {
    return getFirstSelectedEmailMarketingCampaign();
  }

  return getFirstSelectedMailingList();
};

/**
 * @param {string} itemType
 * @param {array} selectedItemIds
 */
export const updateWindowLocationHash = (itemType, selectedItemIds) => {
  let computedHash = '/email-marketing-campaign-performance/' + itemType;
  if (selectedItemIds.length > 0) {
    computedHash += '/' + selectedItemIds[INPUT_SELECTED_ITEM_ID_INDEX.FIRST_ID];
  }
  if (selectedItemIds.length > 1) {
    computedHash += '/' + selectedItemIds[INPUT_SELECTED_ITEM_ID_INDEX.SECOND_ID];
  }

  window.location.hash = computedHash;
};

export const sanitizeFilename = (name) => {
  // Trim leading and trailing spaces, and remove invalid characters
  const cleanedName = name.trim().replace(/[^a-zA-Z0-9_ ]/g, ''); // Allow only alphanumeric characters, underscores, and spaces

  // Replace consecutive spaces with a single space
  const cleanedSpaces = cleanedName.replace(/\s+/g, ' ');

  // Truncate length (adjust the max length as needed)
  const maxLength = 255; // Example maximum filename length
  const truncatedFilename = cleanedSpaces.substring(0, maxLength);

  return truncatedFilename;
};
