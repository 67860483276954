import type {ReactElement} from 'react';
import React, {useEffect, useRef} from 'react';
import type {SidebarListItemContent} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar.types';
import {noop} from '@Utils/general.util';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import {cleanupPopover, initSidebarPopover, onScrollHandler} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar-popover-helper';
import styles from './mobile-sidebar-item.module.scss';

type MobileSidebarItemProps = Omit<SidebarListItemContent, 'text' | 'dialogMode'> & {
  itemPopover?: ReactElement;
  popoverRef?: React.RefObject<HTMLLIElement>;
};

export function MobileSidebarItem({
  icon,
  isSelected,
  url,
  onClick = noop,
  isLink = true,
  showPremiumIcon = false,
  className = '',
  itemPopover,
  popoverRef,
}: MobileSidebarItemProps): ReactElement {
  const refForPopoverWrapper = useRef<HTMLDivElement>(null);

  const onScroll = (): void => {
    if (popoverRef === undefined || isSelected) {
      return;
    }

    onScrollHandler(refForPopoverWrapper, popoverRef);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll, {passive: true});

    return () => {
      document.removeEventListener('scroll', onScroll);
      cleanupPopover(refForPopoverWrapper);
    };
  }, [isSelected]);

  useEffect((): void => {
    if (itemPopover === undefined || popoverRef === undefined || isSelected) {
      return;
    }

    initSidebarPopover(itemPopover, popoverRef, refForPopoverWrapper);
  }, [popoverRef?.current, isSelected]);

  const getContent = (): ReactElement => {
    return (
      <>
        <Icon icon={icon} size={IconSize.SIZE_ICON_20} type={isSelected ? IconType.PRIMARY : IconType.GHOST} shape={IconShape.SQUARE} className="_full-width" />
        {showPremiumIcon ? <Icon icon="icon-crown" size={IconSize.SIZE_ICON_16} className={`${styles.premiumIcon} color-premium`} /> : null}
      </>
    );
  };

  const getContentWrappedInUsableParentTag = (): ReactElement => {
    const classes = `${styles.mobileSidebarItemInner} _full-width _unpadded`;

    if (isLink) {
      return (
        <a href={url} className={classes}>
          {getContent()}
        </a>
      );
    }

    return (
      <button className={classes} type="button" onClick={onClick}>
        {getContent()}
      </button>
    );
  };

  return (
    <li ref={popoverRef} className={`${styles.mobileSidebarItem} flex-center ${className} _unpadded-bottom`}>
      {getContentWrappedInUsableParentTag()}
    </li>
  );
}
