import type {ReactElement} from 'react';
import React from 'react';
import styles from '@Components/mystuff-left-sidebar/components/sidebar-list-item/sidebar-list-item.module.scss';
import {createRoot} from 'react-dom/client';

export const getWrapperForPopover = (popover: ReactElement, computedTop: number, computedLeft: number, refForPopoverWrapper: React.RefObject<HTMLDivElement>): ReactElement => {
  return (
    <div style={{top: `${computedTop}px`, left: `${computedLeft}px`}} ref={refForPopoverWrapper} className={styles.wrapperClasses}>
      {popover}
    </div>
  );
};

export const getPopoverLeft = (popoverRef: React.RefObject<HTMLLIElement>): number => {
  if (!popoverRef || !popoverRef.current) {
    return 0;
  }

  const popoverWidth = popoverRef.current.getBoundingClientRect().width;
  return popoverRef.current.getBoundingClientRect().left + popoverWidth;
};

export const getPopoverTop = (popoverRef: React.RefObject<HTMLLIElement>): number => {
  if (!popoverRef || !popoverRef.current) {
    return 0;
  }

  const offset = 10;
  return popoverRef.current.getBoundingClientRect().top + offset;
};

export const onScrollHandler = (refForPopoverWrapper: React.RefObject<HTMLDivElement>, popoverRef: React.RefObject<HTMLLIElement>): void => {
  const currentScrollY = window.scrollY;
  const nav = $('#nav');
  const navHeight = nav.height() ?? 60;
  const isNavInScrollView = currentScrollY - navHeight <= 0;

  if (!refForPopoverWrapper || !refForPopoverWrapper.current || !popoverRef || !popoverRef.current) {
    return;
  }

  const topPopoverRef = getPopoverTop(popoverRef);
  const leftPopoverRef = getPopoverLeft(popoverRef);

  refForPopoverWrapper.current.style.top = `${topPopoverRef}px`;
  refForPopoverWrapper.current.style.left = `${leftPopoverRef}px`;

  if (isNavInScrollView) {
    refForPopoverWrapper.current.classList.remove(styles.anchorClassesHiddenNav);
    return;
  }

  refForPopoverWrapper.current.classList.add(styles.anchorClassesHiddenNav);
};

export const initSidebarPopover = (itemPopover: ReactElement, popoverRef: React.RefObject<HTMLLIElement>, refForPopoverWrapper: React.RefObject<HTMLDivElement>): void => {
  if (itemPopover && popoverRef && popoverRef.current && !refForPopoverWrapper.current) {
    const topPopoverRef = getPopoverTop(popoverRef);
    const leftPopoverRef = getPopoverLeft(popoverRef);

    const wrapperPopover = getWrapperForPopover(itemPopover, topPopoverRef, leftPopoverRef, refForPopoverWrapper);
    const portalNode = document.createElement('div');
    document.body.appendChild(portalNode);
    const root = createRoot(portalNode);
    root.render(wrapperPopover);
  }
};

export const cleanupPopover = (refForPopoverWrapper: React.RefObject<HTMLDivElement>): void => {
  if (refForPopoverWrapper && refForPopoverWrapper.current) {
    refForPopoverWrapper.current.remove();
  }
};
