import React from 'react';
import {Tag, TagType} from '@Components/tag';

interface NewFeatureTagProps {
  className?: string;
  isPremium?: boolean;
}

export function NewFeatureTag({className = '', isPremium = false}: NewFeatureTagProps) {
  return (
    <Tag
      className={className}
      text={window.i18next.t('pmwjs_new')}
      textClasses="spacing-m-l-0"
      type={TagType.SECONDARY}
      icon={isPremium ? 'icon-crown' : ''}
      iconClasses={isPremium ? 'color-premium-light spacing-m-r-2' : ''}
    />
  );
}
