import {getCurrentUTCMonthIndex, getDateFromUnixTimestamp, getFirstDayOfMonth, getNextUTCMonthIndex, getReadableMonthNameForIndex} from '@Utils/date.util';
import {isLoading, LoadingStates} from '@Utils/loading.util';
import {formatNumberInUSD} from '@Utils/number.util';
import {LifetimeEarningsTableColumnIds} from '@Components/designer-earnings-report/designer-earnings-report.types';

export const MIN_AMOUNT_FOR_PAYOUT_IN_USD = 50;
export const CIRCULAR_PROGRESS_BAR_STROKE_WIDTH_THICKER = 8;
export const EMPTY_PROGRESS_VALUE = 0;
export const DEFAULT_CIRCULAR_PROGRESS_FILL_VALUE = 1;
export const DESIGNER_EARNINGS_DOTS_SIZE = 8;
export const DESIGNER_EARNINGS_DOTS_DISTANCE = 8;
export const DESIGNER_EARNINGS_DOTS_SIZE_SMALL = 5;
export const DESIGNER_EARNINGS_DOTS_DISTANCE_LESS = 5;
export const LAZY_PAYOUTS_LIMIT = 100;
export const LAZY_ROYALTIES_LIMIT = 100;

export const LAZY_TEMPLATE_EARNINGS_LIMIT = 50;
export const PAYOUTS_ID_NAME = 'payoutId';
export const ROYALTIES_ID_NAME = 'royaltyId';

export const TEMPLATES_LIFETIME_EARNINGS_ID_NAME = 'posterId';
export const PAYOUTS_HELP_CENTER_ARTICLE_LINK = 'https://support.postermywall.com/hc/en-us/articles/360015491212-When-will-I-get-paid';
export const ROYALTIES_HELP_CENTER_ARTICLE = 'https://support.postermywall.com/hc/en-us/articles/360018268171';

/**
 * default value for all designer earnings stats when data is loading/hasn't loaded
 */
export const DEFAULT_DESIGNER_EARNINGS_VALUE = 0;
/**
 * the minimum amount we can display in two decimal places after which we need to show the royalty differently in the UI
 */
export const MINIMUM_ROYALTY_DISPLAY_AMOUNT = 0.01;

const NUM_DECIMALS_IN_ROYALTY_AMOUNT = 4;
/**
 * text we show in the UI if the royalty is less than 0.01 USD
 */
const ROYALTY_LESS_THAN_A_PENNY_TEXT = 'US$ <0.01';

/**
 * the max number we will show in the UI after which we apply formatting for templates that 'need attention'
 */
const MAX_NEEDS_ATTENTION_UI_NUM = 100;

const royaltyCurrencyFormatter = Intl.NumberFormat(navigator.language, {
  style: 'currency',
  currency: window.PMW.CURRENCY_CODE_USD,
  maximumFractionDigits: NUM_DECIMALS_IN_ROYALTY_AMOUNT,
});

export interface DesignerEarningsInPeriod {
  totalRoyalties: number;
  totalUses: number;
  standardPurchases: number;
  totalPurchases: number;
  pendingPremiumPurchases: number;
}

export interface DesignerEarningsDataTotal extends DesignerEarningsInPeriod {
  totalPayouts: number;
  processedPremiumPurchases: number;
  pendingPremiumPurchasesPrevMonth?: number;
}

export interface DesignerEarningsDataForMonth extends DesignerEarningsInPeriod {
  currentMonth: number;
}

export interface DesignerEarningsData {
  lastProcessedDate: string;
  currentMonthStartDate: number;
  unpaidRoyalties: number;
  dataTotal: DesignerEarningsDataTotal;
  dataMonth: DesignerEarningsDataForMonth;
  lastFetchedOn?: Date;
}

export enum RoyaltyPurchaseType {
  PREMIUM = 'premium',
  STANDARD = 'standard',
  REFUNDED = 'refunded',
}

export enum PaymentType {
  PROCESSED = 'processed',
  PENDING = 'pending',
}

export type PurchaseTypeFilterValues = Record<RoyaltyPurchaseType, boolean>;
export type PaymentStatusTypeFilterValues = Record<PaymentType, boolean>;

export enum RoyaltyPaymentStatus {
  STANDARD_PROCESSED = 1,
  PREMIUM_PENDING = 2,
  PREMIUM_PROCESSED = 3,
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export interface DesignersLazyData {
  ids: Array<number>;
  loadingState: LoadingStates;
  loadMore: boolean;
}

export type DesignerPayouts = Record<number, DesignerPayout>;
export type DesignerRoyalties = Record<number, DesignerRoyalty>;
export type DesignerTemplateLifetimeEarnings = Record<number, DesignerTemplateLifetimeEarning>;

export interface RoyaltyTemplateMeta {
  posterId: number;
  posterHashedId: string;
  posterPreviewURL: string;
  designName: string;
}

export interface DesignerPayout {
  payoutId: number;
  amount: number;
  transactionId: string;
  paidOnTimestamp: number;
  paidOnDate: string;
  paidOnDateShort: string;
}

export interface DesignerRoyalty extends RoyaltyTemplateMeta {
  royaltyId: number;
  amount: number;
  paymentStatus: RoyaltyPaymentStatus;
  type: RoyaltyPurchaseType;
  createdOnDate: string;
  createdOnDateShort: string;
  createdOnTimestamp: number;
  isPurchaseFromLocalisedCurrency: boolean;
  wasPurchasedOnDiscount: boolean;
}

export interface DesignerTemplateLifetimeEarning extends RoyaltyTemplateMeta {
  totalAmount: number;
  publishOnTimestamp: number;
  publishOnDate: string;
  publishOnDateShort: string;
}

export interface TemplateLifetimeEarningLazyData extends DesignersLazyData {
  sortDirection: SortDirection;
  sortColumnId: LifetimeEarningsTableColumnIds;
}

export interface RoyaltyFilters {
  purchaseType: Record<RoyaltyPurchaseType, boolean>;
  paymentStatusType: Record<PaymentType, boolean>;
}

export interface LazyLoadAjaxParams {
  limit: number;
  offsetId?: number;
}

export interface DesignerRoyaltiesAjaxParams extends LazyLoadAjaxParams {
  getRefundedRoyalties: boolean;
  paymentStatuses: RoyaltyPaymentStatus[];
}

export interface DesignerTemplateEarningsAjaxParams extends Omit<LazyLoadAjaxParams, 'offsetId'> {
  isSortingByPublishTime: boolean;
  sortDirection: SortDirection;
  offset?: number;
  offsetTimestamp?: number;
}

export const openRoyaltiesHelpCenterArticle = (): void => {
  window.open(ROYALTIES_HELP_CENTER_ARTICLE, '_blank');
};

export const areRoyaltiesEligibleForPayout = (unpaidRoyalties: number): boolean => {
  return unpaidRoyalties >= MIN_AMOUNT_FOR_PAYOUT_IN_USD;
};

export const getDefaultPurchaseFiltersHashmap = (): PurchaseTypeFilterValues => {
  return {
    [RoyaltyPurchaseType.PREMIUM]: true,
    [RoyaltyPurchaseType.STANDARD]: true,
    [RoyaltyPurchaseType.REFUNDED]: true,
  };
};

export const getDefaultPaymentStatusFiltersHashmap = (): PaymentStatusTypeFilterValues => {
  return {
    [PaymentType.PENDING]: true,
    [PaymentType.PROCESSED]: true,
  };
};

export const getOffsetIdForLazyBatch = (lazyData?: DesignersLazyData): number | undefined => {
  if (!lazyData) {
    return undefined;
  }

  return lazyData.ids.length === 0 ? undefined : Math.min(...lazyData.ids);
};

export const getOffsetTimestampForLazyBatch = (
  lazyData: TemplateLifetimeEarningLazyData,
  allTemplateEarnings: DesignerTemplateLifetimeEarnings,
  sortDirection: SortDirection
): number | undefined => {
  if (lazyData.ids.length === 0) {
    return undefined;
  }

  const timestamps: number[] = [];
  lazyData.ids.forEach((id) => {
    if (allTemplateEarnings[id]) {
      timestamps.push(allTemplateEarnings[id].publishOnTimestamp);
    }
  });

  return sortDirection === SortDirection.ASC ? Math.max(...timestamps) : Math.min(...timestamps);
};

export const shouldTemplateEarningsBeSortedByPublishTime = (templateEarnings: TemplateLifetimeEarningLazyData): boolean => {
  return templateEarnings.sortColumnId === LifetimeEarningsTableColumnIds.PUBLISHED_ON;
};

export const areAllFiltersSelected = (filterHashmap: PurchaseTypeFilterValues | PaymentStatusTypeFilterValues): boolean => {
  return Object.values(filterHashmap).indexOf(false) < 0;
};

export const areAllValuesSelectedInFilters = (purchaseTypeFilter: PurchaseTypeFilterValues, paymentStatusTypeFilter: PaymentStatusTypeFilterValues): boolean => {
  return areAllFiltersSelected(purchaseTypeFilter) && areAllFiltersSelected(paymentStatusTypeFilter);
};

export const isPremiumPurchaseTypeSelectedInFilter = (filterHashmap: PurchaseTypeFilterValues): boolean => {
  return filterHashmap[RoyaltyPurchaseType.PREMIUM];
};

export const isStandardPurchaseTypeSelectedInFilter = (filterHashmap: PurchaseTypeFilterValues): boolean => {
  return filterHashmap[RoyaltyPurchaseType.STANDARD];
};

export const isPendingPaymentTypeSelectedInFilter = (filterHashmap: PaymentStatusTypeFilterValues): boolean => {
  return filterHashmap[PaymentType.PENDING];
};

export const isProcessedPaymentTypeSelectedInFilter = (filterHashmap: PaymentStatusTypeFilterValues): boolean => {
  return filterHashmap[PaymentType.PROCESSED];
};

export const isRefundedRoyaltyFilterSelected = (filterHashmap: PurchaseTypeFilterValues): boolean => {
  return filterHashmap[RoyaltyPurchaseType.REFUNDED];
};

export const isPremiumProcessedRoyalty = (paymentStatus: RoyaltyPaymentStatus): boolean => {
  return paymentStatus === RoyaltyPaymentStatus.PREMIUM_PROCESSED;
};

export const isPremiumPendingRoyalty = (paymentStatus: RoyaltyPaymentStatus): boolean => {
  return paymentStatus === RoyaltyPaymentStatus.PREMIUM_PENDING;
};

export const isRefundedRoyalty = (purchaseType: RoyaltyPurchaseType): boolean => {
  return purchaseType === RoyaltyPurchaseType.REFUNDED;
};

export const isStandardRoyalty = (purchaseType: RoyaltyPurchaseType): boolean => {
  return purchaseType === RoyaltyPurchaseType.STANDARD;
};

export const isPremiumRoyalty = (purchaseType: RoyaltyPurchaseType): boolean => {
  return purchaseType === RoyaltyPurchaseType.PREMIUM;
};

export const isStandardRoyaltyComingFromLocalisedCurrencyPurchase = (royalty: DesignerRoyalty): boolean => {
  return isStandardRoyalty(royalty.type) && royalty.isPurchaseFromLocalisedCurrency;
};

export const isStandardRoyaltyComingFromDiscountedPurchase = (royalty: DesignerRoyalty): boolean => {
  return isStandardRoyalty(royalty.type) && royalty.wasPurchasedOnDiscount;
};

export const canRoyaltyBeDisplayedInTwoDecimalPlaces = (royalty: DesignerRoyalty): boolean => {
  return royalty.amount >= MINIMUM_ROYALTY_DISPLAY_AMOUNT;
};

export const doesRoyaltyHaveTooltipExplanation = (royalty: DesignerRoyalty): boolean => {
  return (
    isPremiumPendingRoyalty(royalty.paymentStatus) ||
    isRefundedRoyalty(royalty.type) ||
    isStandardRoyaltyComingFromLocalisedCurrencyPurchase(royalty) ||
    isStandardRoyaltyComingFromDiscountedPurchase(royalty) ||
    !canRoyaltyBeDisplayedInTwoDecimalPlaces(royalty)
  );
};

export const getPaymentStatusesForFetchingRoyalties = (
  purchaseTypeFilter: PurchaseTypeFilterValues,
  paymentStatusTypeFilter: PaymentStatusTypeFilterValues
): RoyaltyPaymentStatus[] => {
  const paymentStatuses: RoyaltyPaymentStatus[] = [];

  if (isStandardPurchaseTypeSelectedInFilter(purchaseTypeFilter)) {
    paymentStatuses.push(RoyaltyPaymentStatus.STANDARD_PROCESSED);
  }

  if (isPremiumPurchaseTypeSelectedInFilter(purchaseTypeFilter) && isPendingPaymentTypeSelectedInFilter(paymentStatusTypeFilter)) {
    paymentStatuses.push(RoyaltyPaymentStatus.PREMIUM_PENDING);
  }

  if (isPremiumPurchaseTypeSelectedInFilter(purchaseTypeFilter) && isProcessedPaymentTypeSelectedInFilter(paymentStatusTypeFilter)) {
    paymentStatuses.push(RoyaltyPaymentStatus.PREMIUM_PROCESSED);
  }

  return paymentStatuses;
};
export const getRoyaltyPercentageTowardsPayout = (unpaidRoyalties: number): number => {
  return (unpaidRoyalties / MIN_AMOUNT_FOR_PAYOUT_IN_USD) * 100;
};

export const getDesignerEarningStatistic = (earningMetric?: number): number => {
  const statistic = earningMetric ?? DEFAULT_DESIGNER_EARNINGS_VALUE;
  return statistic < 0 ? DEFAULT_DESIGNER_EARNINGS_VALUE : statistic;
};

export const getPendingPremiumPurchasesForCurrentMonth = (designerEarningsData?: DesignerEarningsData): number => {
  return (
    getDesignerEarningStatistic(designerEarningsData?.dataTotal?.pendingPremiumPurchases) -
    getDesignerEarningStatistic(designerEarningsData?.dataTotal?.pendingPremiumPurchasesPrevMonth)
  );
};

export const getDesignerEarningsInPeriod = (earningsInPeriod?: DesignerEarningsInPeriod): DesignerEarningsInPeriod => {
  return {
    totalPurchases: getDesignerEarningStatistic(earningsInPeriod?.totalPurchases),
    totalUses: getDesignerEarningStatistic(earningsInPeriod?.totalUses),
    totalRoyalties: getDesignerEarningStatistic(earningsInPeriod?.totalRoyalties),
    standardPurchases: getDesignerEarningStatistic(earningsInPeriod?.standardPurchases),
    pendingPremiumPurchases: getDesignerEarningStatistic(earningsInPeriod?.pendingPremiumPurchases),
  };
};

export const getLifeTimeDesignerEarnings = (designerData?: DesignerEarningsData): DesignerEarningsDataTotal => {
  return {
    ...getDesignerEarningsInPeriod(designerData?.dataTotal),
    pendingPremiumPurchases: getPendingPremiumPurchasesForCurrentMonth(designerData),
    totalPayouts: getDesignerEarningStatistic(designerData?.dataTotal?.totalPayouts),
    processedPremiumPurchases: getDesignerEarningStatistic(designerData?.dataTotal?.processedPremiumPurchases),
    pendingPremiumPurchasesPrevMonth: designerData?.dataTotal.pendingPremiumPurchasesPrevMonth,
  };
};

export const getDesignerEarningsForMonthPeriod = (designerData?: DesignerEarningsData): DesignerEarningsDataForMonth => {
  return {
    ...getDesignerEarningsInPeriod(designerData?.dataMonth),
    currentMonth: designerData?.dataMonth.currentMonth ?? getCurrentUTCMonthIndex(),
  };
};

export const getInitialLazyLoadedData = (): DesignersLazyData => {
  return {
    ids: [],
    loadingState: LoadingStates.NOT_LOADED,
    loadMore: true,
  };
};

export const getInitialLazyDataForTemplateEarnings = (
  sortColumnId: LifetimeEarningsTableColumnIds,
  sortDirection: SortDirection = SortDirection.DESC
): TemplateLifetimeEarningLazyData => {
  return {
    ...getInitialLazyLoadedData(),
    sortDirection,
    sortColumnId,
  };
};

export const getAppropriateRoyaltiesState = (royaltyFilters: RoyaltyFilters, royalties: DesignersLazyData, filteredRoyalties: DesignersLazyData): DesignersLazyData => {
  return areAllValuesSelectedInFilters(royaltyFilters.purchaseType, royaltyFilters.paymentStatusType) ? royalties : filteredRoyalties;
};

export const getAjaxParamsForFetchingRoyalties = (
  royaltyFilters: RoyaltyFilters,
  royalties: DesignersLazyData,
  filteredRoyalties: DesignersLazyData
): DesignerRoyaltiesAjaxParams => {
  const royaltiesToCheck = getAppropriateRoyaltiesState(royaltyFilters, royalties, filteredRoyalties);

  return {
    offsetId: getOffsetIdForLazyBatch(royaltiesToCheck),
    limit: LAZY_ROYALTIES_LIMIT,
    paymentStatuses: getPaymentStatusesForFetchingRoyalties(royaltyFilters.purchaseType, royaltyFilters.paymentStatusType),
    getRefundedRoyalties: isRefundedRoyaltyFilterSelected(royaltyFilters.purchaseType),
  };
};

export const shouldMoreRoyaltiesBeFetched = (royaltyFilters: RoyaltyFilters, royalties: DesignersLazyData, filteredRoyalties: DesignersLazyData): boolean => {
  const royaltiesToCheck = getAppropriateRoyaltiesState(royaltyFilters, royalties, filteredRoyalties);
  return shouldLazyDataBeFetched(royaltiesToCheck);
};

export const shouldLazyDataBeFetched = (lazyData: DesignersLazyData): boolean => {
  return lazyData.loadMore && !isLoading(lazyData.loadingState);
};

export const willRoyaltyBeProcessedSoon = (lastProcessedDate: string, royaltyCreatedOnTimestamp: number): boolean => {
  const royaltyCreatedOnDate = getDateFromUnixTimestamp(royaltyCreatedOnTimestamp);
  const currentMonthFirstDate = getFirstDayOfMonth();
  const lastProcessedDateObj = new Date(lastProcessedDate);

  return currentMonthFirstDate > royaltyCreatedOnDate && currentMonthFirstDate > lastProcessedDateObj;
};

export const getRoyaltyTooltipText = (royalty: DesignerRoyalty, lastProcessedDate: string): string => {
  if (isPremiumPendingRoyalty(royalty.paymentStatus)) {
    return getPendingRoyaltyTooltipText(lastProcessedDate, royalty.createdOnTimestamp);
  }

  if (isRefundedRoyalty(royalty.type)) {
    return window.i18next.t('pmwjs_this_purchase_was_refunded');
  }

  if (isStandardRoyaltyComingFromLocalisedCurrencyPurchase(royalty)) {
    return window.i18next.t('pmwjs_purchase_made_using_localised_pricing');
  }

  if (isStandardRoyaltyComingFromDiscountedPurchase(royalty)) {
    return window.i18next.t('pmwjs_purchase_made_using_discount_code');
  }

  if (!canRoyaltyBeDisplayedInTwoDecimalPlaces(royalty)) {
    return royaltyCurrencyFormatter.format(royalty.amount);
  }

  return '';
};

export const getPendingRoyaltyTooltipText = (lastProcessedDate: string, royaltyCreatedOnTimestamp: number): string => {
  const nextMonthFirstDayDate = `${getReadableMonthNameForIndex(getNextUTCMonthIndex())} 1st`;
  return willRoyaltyBeProcessedSoon(lastProcessedDate, royaltyCreatedOnTimestamp)
    ? window.i18next.t('pmwjs_will_be_processed_shortly')
    : window.i18next.t('pmwjs_will_be_processed_after_x', {shortDate: nextMonthFirstDayDate});
};

export const getTextForRoyaltyAmount = (royalty: DesignerRoyalty): string => {
  if (isPremiumPendingRoyalty(royalty.paymentStatus)) {
    return window.i18next.t('pmwjs_pending');
  }

  if (!canRoyaltyBeDisplayedInTwoDecimalPlaces(royalty)) {
    return ROYALTY_LESS_THAN_A_PENNY_TEXT;
  }

  return formatNumberInUSD(royalty.amount);
};

export const getNumTemplatesThatNeedAttention = (): number => {
  return window.reactPageProps?.numTemplatesNeedAttention as number ?? 0;
}

export const getFormattedNeedsAttentionTemplatesNumber = (): string => {
  const numTemplates = getNumTemplatesThatNeedAttention();

  if (numTemplates > MAX_NEEDS_ATTENTION_UI_NUM) {
    return `${MAX_NEEDS_ATTENTION_UI_NUM}+`;
  }

  return numTemplates.toString();
}

export const areThereTemplatesThatNeedAttention = (): boolean => {
  return getNumTemplatesThatNeedAttention() > 0;
}