import type {ReactElement} from 'react';
import React, {useState, useEffect, useRef} from 'react';
import {noop} from '@Utils/general.util';
import {Icon} from '@Components/icon-v2';
import {IconSize, IconType} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import type {SidebarListItemContent} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar.types';
import {NewFeatureTag} from '@Components/new-feature-tag';
import {cleanupPopover, initSidebarPopover, onScrollHandler} from '@Components/mystuff-left-sidebar/mystuff-left-sidebar-popover-helper';
import {readCookie, setCookie} from '@Utils/cookie.util';
import styles from './sidebar-list-item.module.scss';

const SIDEBAR_BORDER_ANIMATION_COOKIE = 'pmwsab';

export interface SidebarListItemProps extends SidebarListItemContent {
  children?: ReactElement;
  itemPopover?: ReactElement;
  popoverRef?: React.RefObject<HTMLLIElement>;
}

export function SidebarListItem({
  icon,
  text,
  url,
  isSelected,
  children,
  onClick = noop,
  isNewPage = false,
  className = '',
  showPremiumIcon = false,
  isLink = true,
  itemPopover,
  popoverRef,
  hasBorderAnimation = false,
}: SidebarListItemProps): ReactElement {
  const refForPopoverWrapper = useRef<HTMLDivElement>(null);

  const showBorderAnimation = (): boolean => {
    return hasBorderAnimation && !readCookie(SIDEBAR_BORDER_ANIMATION_COOKIE);
  };

  const [showAnimation, setShowAnimation] = useState(showBorderAnimation());

  const onScroll = (): void => {
    if (popoverRef === undefined || isSelected) {
      return;
    }

    onScrollHandler(refForPopoverWrapper, popoverRef);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll, {passive: true});

    return () => {
      document.removeEventListener('scroll', onScroll);
      cleanupPopover(refForPopoverWrapper);
    };
  }, [isSelected]);

  useEffect((): void => {
    if (itemPopover === undefined || popoverRef === undefined || isSelected) {
      return;
    }

    initSidebarPopover(itemPopover, popoverRef, refForPopoverWrapper);
  }, [popoverRef?.current, isSelected]);

  const getContent = (): ReactElement => {
    const textColorClass = isSelected ? 'content-primary' : 'content-body';

    return (
      <>
        <div className={`${styles.mainContent} flex-row-align-center`}>
          <Icon icon={icon} className={`${textColorClass} spacing-m-r-2`} size={IconSize.SIZE_ICON_16} type={IconType.NONE} />
          <Text val={text} size={TextSize.XSMALL} bold={isSelected} className={`${styles.sidebarText} ${textColorClass}`} />
        </div>
        <div className={`${styles.secondaryContent} flex-row-align-center`}>
          {showPremiumIcon && !isNewPage ? <Icon icon="icon-crown" className="color-premium" size={IconSize.SIZE_ICON_16} /> : null}
          {isNewPage ? <NewFeatureTag isPremium={showPremiumIcon} className={`${styles.newTag} radius-round spacing-p-l-2 spacing-p-r-2 spacing-p-t-1 spacing-p-b-1`} /> : null}
          {children}
        </div>
      </>
    );
  };

  const getContentWrappedInUsableParentTag = (): ReactElement => {
    const classes = `${styles.sidebarInnerItem} flex-row-align-center flex-justify-between _full-width _full-height _unpadded`;

    if (isLink) {
      return (
        <a className={classes} href={url}>
          {getContent()}
        </a>
      );
    }

    return (
      <button className={classes} onClick={onClick} type="button">
        {getContent()}
      </button>
    );
  };

  return (
    <li
      ref={popoverRef}
      className={`${styles.sidebarListItem} ${isSelected ? styles.selected : ''} ${className} _full-width spacing-p-r-3 spacing-p-l-3 _unpadded-bottom hover-transition-all ${showAnimation ? styles.animatedBorder : ''}`}
      onClick={() => {
        if (showAnimation) {
          setCookie(SIDEBAR_BORDER_ANIMATION_COOKIE, '1', 7);
          setShowAnimation(false);
        }
      }}
      onAnimationEnd={() => {
        setShowAnimation(false);
      }}
    >
      {getContentWrappedInUsableParentTag()}
    </li>
  );
}
