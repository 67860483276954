import type { PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import {contentPlannerExtraReducers, modifyEntriesInContentPlanner} from '@Components/content-planner/content-planner-extra-reducers';
import type {
  BaseEvent,
  ContentPlannerEntries,
  ContentPlannerState,
  EditScheduledEntryDateParams,
  EntriesMapForPlannerCell,
  EntryTypeFilter,
  ScheduledEntry,
  SocialGlobalEventFilter} from '@Components/content-planner/content-planner.types';
import {
  PlannerEntryCellOperation
} from '@Components/content-planner/content-planner.types';
import type {
  Dictionary} from '@Libraries/content-planner-library';
import {
  getEmptyBaseEventEntry,
  getEntriesMapKey,
  getEntryTypeFiltersInitialState,
  getEventTypeFiltersInitialState,
  isDateInCalendar,
} from '@Libraries/content-planner-library';
import type {FiltersHashmap} from '@Components/checkbox-filters/checkbox-filters.types';
import {getDateFromUnixTimestamp, getUnixTimestamp, isToday} from '@Utils/date.util';

const initialState: ContentPlannerState = {
  date: new Date(),
  activeDate: new Date(),
  entriesMap: {},
  contentPlannerFilters: {
    entryTypeFilters: getEntryTypeFiltersInitialState(),
    socialGlobalEventFilters: getEventTypeFiltersInitialState(),
    eventRegionFilters: {},
    eventReligionFilters: {},
  },
  regions: [],
  religions: [],
  customEventFormData: getEmptyBaseEventEntry(),
  isNewEventBeingCreated: false,
};

export const contentPlannerSlice = createSlice({
  name: 'contentPlanner',
  initialState,
  reducers: {
    updateContentPlannerStateDate: (state, {payload}: PayloadAction<Date>) => {
      updateDate(state, payload);
    },
    updateActiveDateState: (state, {payload}: PayloadAction<Date>) => {
      updateActiveDate(state, payload);
    },
    updateEntryTypeFilters: (state, {payload}: PayloadAction<EntryTypeFilter>) => {
      state.contentPlannerFilters.entryTypeFilters = payload;
    },
    updateSocialGlobalEventFilters: (state, {payload}: PayloadAction<SocialGlobalEventFilter>) => {
      state.contentPlannerFilters.socialGlobalEventFilters = payload;
    },
    updateEventRegionFilters: (state, {payload}: PayloadAction<FiltersHashmap>) => {
      state.contentPlannerFilters.eventRegionFilters = payload;
    },
    updateEventReligionFilters: (state, {payload}: PayloadAction<FiltersHashmap>) => {
      state.contentPlannerFilters.eventReligionFilters = payload;
    },
    setCustomEventFormData: (state, {payload}: PayloadAction<BaseEvent>) => {
      state.customEventFormData = payload;
    },
    setCustomEventFormTitle: (state, {payload}: PayloadAction<string>) => {
      state.customEventFormData.title = payload;
    },
    setCustomEventFormDescription: (state, {payload}: PayloadAction<string>) => {
      state.customEventFormData.description = payload;
    },
    setCustomEventFormTimestamp: (state, {payload}: PayloadAction<number>) => {
      state.customEventFormData.timestamp = payload;
    },
    setIsCustomEventBeingCreated: (state, {payload}: PayloadAction<boolean>) => {
      state.isNewEventBeingCreated = payload;
    },
    startEventCreationFlowForDate: (state, {payload}: PayloadAction<Date>) => {
      doStartEventCreationFlowForDate(state, payload);
    },
    updateContentPlannerDateIfNeeded: (state, {payload}: PayloadAction<Date>) => {
      updateDateIfNeeded(state, payload);
    },
    markCurrentDateEntriesForReload: (state) => {
      const entriesMapKey = getEntriesMapKey(state.date);
      if (state.entriesMap[entriesMapKey] === undefined) {
        return;
      }
      state.entriesMap[entriesMapKey].areEntriesPopulated = false;
    },
    updateScheduledEntryInContentPlanner: (state, {payload}: PayloadAction<EditScheduledEntryDateParams>) => {
      doUpdateScheduledEntryInContentPlanner(state, payload);
    },
  },
  extraReducers: contentPlannerExtraReducers,
});

const updateDate = (state: ContentPlannerState, date: Date): void => {
  state.date = date;
};

const updateDateIfNeeded = (state: ContentPlannerState, date: Date): void => {
  if (isToday(date) || !isDateInCalendar(state.date, date)) {
    updateDate(state, date);
  }
};

const updateActiveDate = (state: ContentPlannerState, date: Date): void => {
  state.activeDate = date;
};

function mergeEntryMaps(existingMap: Dictionary<ContentPlannerEntries>, mapToMerge: Dictionary<ContentPlannerEntries>): Dictionary<ContentPlannerEntries> {
  if (!mapToMerge && !existingMap) {
    return {};
  }

  if (!mapToMerge) {
    return existingMap;
  }

  if (!existingMap) {
    return mapToMerge;
  }

  const entryMapToReturn = {...existingMap};
  const existingMapKeys = Object.keys(existingMap);
  Object.keys(mapToMerge).forEach((key) => {
    if (existingMapKeys.includes(key)) {
      const scheduledEntriesToAdd = [...existingMap[key].scheduledEntries, ...mapToMerge[key].scheduledEntries];
      const eventEntriesToAdd = [...existingMap[key].eventEntries, ...mapToMerge[key].eventEntries];

      entryMapToReturn[key] = {
        scheduledEntries: scheduledEntriesToAdd,
        eventEntries: eventEntriesToAdd,
      };
    } else {
      entryMapToReturn[key] = mapToMerge[key];
    }
  });
  return entryMapToReturn;
}

export const updateEntries = (state: ContentPlannerState, entries: EntriesMapForPlannerCell, entriesMapKey: string): void => {
  state.entriesMap[entriesMapKey] = {
    entriesMappedByDate: mergeEntryMaps(state.entriesMap[entriesMapKey]?.entriesMappedByDate, entries.entriesMappedByDate),
    areEntriesPopulated: entries.areEntriesPopulated,
    lazyLoadingData: entries?.lazyLoadingData,
  };
};

const doStartEventCreationFlowForDate = (state: ContentPlannerState, date: Date): void => {
  updateDateIfNeeded(state, date);
  updateActiveDate(state, date);
  state.isNewEventBeingCreated = true;
  state.customEventFormData = getEmptyBaseEventEntry(date);
};

const doUpdateScheduledEntryInContentPlanner = (state: ContentPlannerState, entryParams: EditScheduledEntryDateParams): void => {
  const entriesMapKey = getEntriesMapKey(state.date);
  const entriesMapForPlannerCell = state.entriesMap[entriesMapKey];
  const scheduledEntry = getScheduledEntryFromEntriesMap(entriesMapForPlannerCell, entryParams.id);

  if (!scheduledEntry) {
    return;
  }

  modifyEntriesInContentPlanner({
    operation: PlannerEntryCellOperation.DELETE,
    date: getDateFromUnixTimestamp(scheduledEntry.timestamp),
    scheduledEntry,
    state,
  });

  scheduledEntry.timestamp = getUnixTimestamp(entryParams.newDate);

  modifyEntriesInContentPlanner({
    operation: PlannerEntryCellOperation.ADD,
    date: entryParams.newDate,
    scheduledEntry,
    state,
  });
};

const getScheduledEntryFromEntriesMap = (entriesMapForPlannerCell: EntriesMapForPlannerCell, id: string): ScheduledEntry | undefined => {
  const allEntriesForKey = Object.values(entriesMapForPlannerCell.entriesMappedByDate);
  let scheduledEntry;

  for (const entriesForDate of allEntriesForKey) {
    scheduledEntry = entriesForDate.scheduledEntries.find((entry) => {
      return entry.id === id;
    });

    if (scheduledEntry) {
      break;
    }
  }

  return scheduledEntry;
};

export const {
  updateContentPlannerStateDate,
  updateActiveDateState,
  updateEntryTypeFilters,
  updateSocialGlobalEventFilters,
  updateEventRegionFilters,
  updateEventReligionFilters,
  setCustomEventFormData,
  setCustomEventFormTitle,
  setCustomEventFormDescription,
  setCustomEventFormTimestamp,
  setIsCustomEventBeingCreated,
  startEventCreationFlowForDate,
  updateContentPlannerDateIfNeeded,
  markCurrentDateEntriesForReload,
  updateScheduledEntryInContentPlanner,
} = contentPlannerSlice.actions;

export const contentPlannerReducer = contentPlannerSlice.reducer;
